import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from '../../../../shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import {baseUrl} from 'config/utils';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR, SHOW_MESSAGE} from 'shared/constants/ActionTypes';

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  newPassword: yup
    .string()
    .nullable()
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          retypeNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting, resetForm}) => {
          setSubmitting(true);

          const JWT = localStorage.getItem('JWTPhoneSheet');
          try {
            let doc = await axios.patch(
              `${baseUrl}master-admin/changePassword`,
              {
                oldPassword: data.oldPassword,
                password: data.newPassword,
                confirmPassword: data.retypeNewPassword,
              },
              {
                headers: {
                  Authorization: JWT,
                },
              },
            );

            if (doc.status == 200) {
              dispatch({type: SHOW_MESSAGE, payload: 'Updated Successfully'});
              resetForm();
            }
          } catch (error) {
            console.log(error);
            dispatch({type: FETCH_ERROR, payload: error.response.data.msg});
          }

          setSubmitting(false);
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
