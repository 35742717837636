import React from 'react';
import VerifyAccount from './VerifyAccount';
const Signin = React.lazy(() => import('./Signin'));
const ForgotPassword = React.lazy(() => import('./ForgetPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const SignUp = React.lazy(() => import('./Signup'));

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-account',
    element: <VerifyAccount />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
];
