import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const UserDash = React.lazy(() => import('./Users/UserDash'));

export const dashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/UserDash',
    element: <UserDash />,
  },
];
