import {FaUserAlt} from 'react-icons/fa';

// import {RiTodoLine} from 'react-icons/ri';
import {
  // injection,
  // medicine,
  // Lab,
  // capsule,
  // eyeIcon,
  // cardIcon,
  userPlaceHolder,
  // AddIcon,
  // AddBreedNameIcon,
  // SubscriptionPlan,
} from 'assets/images';
const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'userDash',
        title: 'Users',

        messageId: 'User Dashboard',
        type: 'item',
        icon: <img src={userPlaceHolder} />,
        url: '/dashboards/UserDash',
        children: [
          {
            id: 'userDash',
            title: 'Users',

            messageId: 'User Dashboard',
            type: 'item',
            icon: <FaUserAlt fill='#2080BD' />,
            url: '/dashboards/UserDash',
          },
        ],
      },
    ],
  },
  // {
  //   id: 'apps',
  //   title: 'Apps',
  //   messageId: 'sidebar.apps',
  //   type: 'group',
  //   children: [

  //     {
  //       id: 'SubsPlan',
  //       title: 'Subscription Plans',
  //       messageId: 'Subscription Plans',
  //       type: 'item',
  //       icon: <img src={SubscriptionPlan} />,
  //       url: '/apps/subscriptionPlans',
  //     },
  //   ],
  // },
];
export default routesConfig;
